import * as z from 'zod'
import { ApiModel } from '~/shared/api/core'
import { Option } from '~/shared/config/constants'

const schema = z.object({
  code: z.string(),
  title: z.string(),
})

export type AggregatorOccupationAttributes = z.infer<typeof schema>

export class AggregatorOccupation extends ApiModel<typeof schema> {
  static jsonApiType = 'aggregator-occupations'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getOption(): Option {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions() {
    const response = await AggregatorOccupation.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
