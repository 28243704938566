import { myPalette } from '~/shared/lib/theme'
import {
  TransmissionEnum,
  FuelTypeEnum,
  RentalContractWorkScheduleEnum,
  TransactionMoveFundsTypeEnum,
  TransactionPaymentTypeEnum,
  TransactionBalanceTypeEnum,
  WeekDaysEnum,
  AppealsStatusEnum,
  CarUnderRepairSubStatusEnum,
  CarAtWorkSubStatusEnum,
  DayEnum,
  CarPropertyTypeEnum,
} from './enums'

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token'
export const USER_PERMISSIONS_STORAGE_KEY = 'user_permissions'
export const USER_TAXIPARK_STORAGE_KEY = 'user_taxipark'
export const USER_REGIONS_STORAGE_KEY = 'user_regions'
export const FILTER_WITHOUT_EMPTY_ENTITIES = '_'
export const UI_SIDEBAR_WIDTH = 208
export const CAR_VIN_LENGTH = 17

export const MIN_COLUMN_WIDTH = 90

export const countries = {
  RU: 'Россия',
  BY: 'Беларусь',
  KG: 'Киргизия',
  AU: 'Австралия',
  AT: 'Австрия',
  AZ: 'Азербайджан',
  AX: 'Аландские острова',
  AL: 'Албания',
  DZ: 'Алжир',
  AS: 'Американское Самоа',
  AI: 'Ангилья',
  AO: 'Ангола',
  AD: 'Андорра',
  AQ: 'Антарктида',
  AG: 'Антигуа и Барбуда',
  AR: 'Аргентина',
  AM: 'Армения',
  AW: 'Аруба',
  AF: 'Афганистан',
  BS: 'Багамы',
  BD: 'Бангладеш',
  BB: 'Барбадос',
  BH: 'Бахрейн',
  BZ: 'Белиз',
  BE: 'Бельгия',
  BJ: 'Бенин',
  BM: 'Бермуды',
  BG: 'Болгария',
  BO: 'Боливия',
  BQ: 'Бонэйр, Синт-Эстатиус и Саба',
  BA: 'Босния и Герцеговина',
  BW: 'Ботсвана',
  BR: 'Бразилия',
  IO: 'Британская территория в Индийском океане',
  BN: 'Бруней',
  BF: 'Буркина-Фасо',
  BI: 'Бурунди',
  BT: 'Бутан',
  VU: 'Вануату',
  VA: 'Ватикан',
  GB: 'Великобритания',
  HU: 'Венгрия',
  VE: 'Венесуэла',
  VG: 'Виргинские Острова (Великобритания)',
  VI: 'Виргинские Острова (США)',
  UM: 'Внешние малые острова (США)',
  TL: 'Восточный Тимор',
  VN: 'Вьетнам',
  GA: 'Габон',
  HT: 'Гаити',
  GY: 'Гайана',
  GM: 'Гамбия',
  GH: 'Гана',
  GP: 'Гваделупа',
  GT: 'Гватемала',
  GF: 'Гвиана',
  GN: 'Гвинея',
  GW: 'Гвинея-Бисау',
  DE: 'Германия',
  GG: 'Гернси',
  GI: 'Гибралтар',
  HN: 'Гондурас',
  HK: 'Гонконг',
  PS: 'Государство Палестина',
  GD: 'Гренада',
  GL: 'Гренландия',
  GR: 'Греция',
  GE: 'Грузия',
  GU: 'Гуам',
  DK: 'Дания',
  CD: 'Демократическая Республика Конго',
  JE: 'Джерси',
  DJ: 'Джибути',
  DM: 'Доминика',
  DO: 'Доминиканская Республика',
  EG: 'Египет',
  ZM: 'Замбия',
  ZW: 'Зимбабве',
  IL: 'Израиль',
  IN: 'Индия',
  ID: 'Индонезия',
  JO: 'Иордания',
  IQ: 'Ирак',
  IR: 'Иран',
  IE: 'Ирландия',
  IS: 'Исландия',
  ES: 'Испания',
  IT: 'Италия',
  YE: 'Йемен',
  KP: 'КНДР (Корейская Народно-Демократическая Республика)',
  CN: 'КНР (Китайская Народная Республика)',
  CV: 'Кабо-Верде',
  KZ: 'Казахстан',
  KH: 'Камбоджа',
  CM: 'Камерун',
  CA: 'Канада',
  QA: 'Катар',
  KE: 'Кения',
  CY: 'Кипр',
  KI: 'Кирибати',
  CC: 'Кокосовые острова',
  CO: 'Колумбия',
  KM: 'Коморы',
  XK: 'Косово',
  CR: 'Коста-Рика',
  CI: 'Кот-д’Ивуар',
  CU: 'Куба',
  KW: 'Кувейт',
  CW: 'Кюрасао',
  LA: 'Лаос',
  LV: 'Латвия',
  LS: 'Лесото',
  LR: 'Либерия',
  LB: 'Ливан',
  LY: 'Ливия',
  LT: 'Литва',
  LI: 'Лихтенштейн',
  LU: 'Люксембург',
  MU: 'Маврикий',
  MR: 'Мавритания',
  MG: 'Мадагаскар',
  YT: 'Майотта',
  MO: 'Макао',
  MW: 'Малави',
  MY: 'Малайзия',
  ML: 'Мали',
  MV: 'Мальдивы',
  MT: 'Мальта',
  MA: 'Марокко',
  MQ: 'Мартиника',
  MH: 'Маршалловы Острова',
  MX: 'Мексика',
  FM: 'Микронезия',
  MZ: 'Мозамбик',
  MD: 'Молдавия',
  MC: 'Монако',
  MN: 'Монголия',
  MS: 'Монтсеррат',
  MM: 'Мьянма',
  NA: 'Намибия',
  NR: 'Науру',
  NP: 'Непал',
  NE: 'Нигер',
  NG: 'Нигерия',
  NL: 'Нидерланды',
  NI: 'Никарагуа',
  NU: 'Ниуэ',
  NZ: 'Новая Зеландия',
  NC: 'Новая Каледония',
  NO: 'Норвегия',
  AE: 'ОАЭ',
  OM: 'Оман',
  BV: 'Остров Буве',
  IM: 'Остров Мэн',
  NF: 'Остров Норфолк',
  CX: 'Остров Рождества',
  KY: 'Острова Кайман',
  CK: 'Острова Кука',
  PN: 'Острова Питкэрн',
  SH: 'Острова Святой Елены, Вознесения и Тристан-да-Кунья',
  PK: 'Пакистан',
  PW: 'Палау',
  PA: 'Панама',
  PG: 'Папуа — Новая Гвинея',
  PY: 'Парагвай',
  PE: 'Перу',
  PL: 'Польша',
  PT: 'Португалия',
  PR: 'Пуэрто-Рико',
  CG: 'Республика Конго',
  KR: 'Республика Корея',
  RE: 'Реюньон',
  RW: 'Руанда',
  RO: 'Румыния',
  EH: 'САДР',
  US: 'США',
  SV: 'Сальвадор',
  WS: 'Самоа',
  SM: 'Сан-Марино',
  ST: 'Сан-Томе и Принсипи',
  SA: 'Саудовская Аравия',
  SZ: 'Свазиленд',
  MK: 'Северная Македония',
  MP: 'Северные Марианские Острова',
  SC: 'Сейшельские Острова',
  BL: 'Сен-Бартелеми',
  MF: 'Сен-Мартен',
  PM: 'Сен-Пьер и Микелон',
  SN: 'Сенегал',
  VC: 'Сент-Винсент и Гренадины',
  KN: 'Сент-Китс и Невис',
  LC: 'Сент-Люсия',
  RS: 'Сербия',
  SG: 'Сингапур',
  SX: 'Синт-Мартен',
  SY: 'Сирия',
  SK: 'Словакия',
  SI: 'Словения',
  SB: 'Соломоновы Острова',
  SO: 'Сомали',
  SD: 'Судан',
  SR: 'Суринам',
  SL: 'Сьерра-Леоне',
  TJ: 'Таджикистан',
  TH: 'Таиланд',
  TW: 'Тайвань',
  TZ: 'Танзания',
  TC: 'Теркс и Кайкос',
  TG: 'Того',
  TK: 'Токелау',
  TO: 'Тонга',
  TT: 'Тринидад и Тобаго',
  TV: 'Тувалу',
  TN: 'Тунис',
  TM: 'Туркмения',
  TR: 'Турция',
  UG: 'Уганда',
  UZ: 'Узбекистан',
  UA: 'Украина',
  WF: 'Уоллис и Футуна',
  UY: 'Уругвай',
  FO: 'Фареры',
  FJ: 'Фиджи',
  PH: 'Филиппины',
  FI: 'Финляндия',
  FK: 'Фолклендские острова',
  FR: 'Франция',
  PF: 'Французская Полинезия',
  TF: 'Французские Южные и Антарктические Территории',
  HM: 'Херд и Макдональд',
  HR: 'Хорватия',
  CF: 'ЦАР',
  TD: 'Чад',
  ME: 'Черногория',
  CZ: 'Чехия',
  CL: 'Чили',
  CH: 'Швейцария',
  SE: 'Швеция',
  SJ: 'Шпицберген и Ян-Майен',
  LK: 'Шри-Ланка',
  EC: 'Эквадор',
  GQ: 'Экваториальная Гвинея',
  ER: 'Эритрея',
  EE: 'Эстония',
  ET: 'Эфиопия',
  ZA: 'ЮАР',
  GS: 'Южная Георгия и Южные Сандвичевы Острова',
  SS: 'Южный Судан',
  JM: 'Ямайка',
  JP: 'Япония',
}

export const DRIVER_KBM_WARNING_COEF = 1.17
export const DRIVER_KBM_ERROR_COEF = 2

export type Option = { id: string; label: string }
export type OptionEnum<T> = { id: T; label: string }

export const transmissionEnumLabels: Record<TransmissionEnum, string> = {
  [TransmissionEnum.AUTO]: 'АКПП',
  [TransmissionEnum.MANUAL]: 'МКПП',
}
export const transmissionEnumOptions: Option[] = Object.keys(
  TransmissionEnum,
).map((value) => {
  const label = transmissionEnumLabels[value as keyof typeof TransmissionEnum]
  return {
    label,
    id: value,
  }
})

export const fuelTypeEnumLabels: Record<FuelTypeEnum, string> = {
  [FuelTypeEnum.AI92]: 'AИ-92',
  [FuelTypeEnum.AI95]: 'AИ-95',
  [FuelTypeEnum.AI98]: 'AИ-98',
  [FuelTypeEnum.AI100]: 'AИ-100',
  [FuelTypeEnum.DIESEL]: 'ДТ',
  [FuelTypeEnum.PROPANE]: 'Пропан',
  [FuelTypeEnum.METHANE]: 'Метан',
  [FuelTypeEnum.ELECTRICITY]: 'Электричество',
}
export const fuelTypeEnumOptions: Option[] = Object.keys(FuelTypeEnum).map(
  (value) => {
    const label = fuelTypeEnumLabels[value as keyof typeof FuelTypeEnum]
    return {
      label,
      id: value,
    }
  },
)

export const carUnderRepairSubStatusEnumLabels: Record<
  CarUnderRepairSubStatusEnum,
  string
> = {
  // [CarUnderRepairSubStatusEnum.ACCIDENT]: 'ДТП',
  // [CarUnderRepairSubStatusEnum.BODY_REPAIR]: 'Кузовной ремонт',
  [CarUnderRepairSubStatusEnum.METALWORK_REPAIR]: 'Слесарный ремонт',
  [CarUnderRepairSubStatusEnum.VEHICLE_ON_THE_PENALTY_AREA]:
    'ТС на штраф-площадке',
  [CarUnderRepairSubStatusEnum.HEAVY_REPAIR]: 'Тяжелый ремонт',
  [CarUnderRepairSubStatusEnum.LOSS_OF_DOCUMENTS]: 'Утеря документов',
  [CarUnderRepairSubStatusEnum.LOSS_OF_KEYS]: 'Утеря ключа',
  [CarUnderRepairSubStatusEnum.HOUSEHOLD_WORK]: 'Хоз работы',
  [CarUnderRepairSubStatusEnum.GUARANTEE]: 'Гарантия',
  [CarUnderRepairSubStatusEnum.DETAILING]: 'Детейлинг',
  [CarUnderRepairSubStatusEnum.BODY_REPAIR_LIGHT]: 'Кузовной ремонт легкий',
  [CarUnderRepairSubStatusEnum.BODY_REPAIR_MEDIUM]: 'Кузовной ремонт средний',
  [CarUnderRepairSubStatusEnum.BODY_REPAIR_HEAVY]: 'Кузовной ремонт тяжелый',
}

export const carUnderRepairSubStatusEnumOptions: OptionEnum<CarUnderRepairSubStatusEnum>[] =
  Object.keys(CarUnderRepairSubStatusEnum).map((value) => {
    const label =
      carUnderRepairSubStatusEnumLabels[
        value as keyof typeof CarUnderRepairSubStatusEnum
      ]
    return {
      label,
      id: value as CarUnderRepairSubStatusEnum,
    }
  })

export const carAtWorkSubStatusEnumLabels: Record<
  CarAtWorkSubStatusEnum,
  string
> = {
  [CarAtWorkSubStatusEnum.CONFISCATION]: 'Изъятие',
  [CarAtWorkSubStatusEnum.BREAKING]: 'Поломка',
  [CarAtWorkSubStatusEnum.TAXISHARING]: 'Таксишеринг',
  [CarAtWorkSubStatusEnum.DETAILING]: 'Детейлинг',
  [CarAtWorkSubStatusEnum.EMPLOYEE]: 'Сотрудник',
  [CarAtWorkSubStatusEnum.TRANSFER]: 'Перегон',
  [CarAtWorkSubStatusEnum.DOCUMENT_INVALID]: 'Документ не действителен',
}

export const carAtWorkSubStatusEnumOptions: OptionEnum<CarAtWorkSubStatusEnum>[] =
  Object.keys(CarAtWorkSubStatusEnum).map((value) => {
    const label =
      carAtWorkSubStatusEnumLabels[value as keyof typeof CarAtWorkSubStatusEnum]

    return {
      label,
      id: value as CarAtWorkSubStatusEnum,
    }
  })

export const rentalContractWorkScheduleEnumOptions: Option[] = Object.keys(
  RentalContractWorkScheduleEnum,
).map((value) => {
  const label =
    RentalContractWorkScheduleEnum[
      value as keyof typeof RentalContractWorkScheduleEnum
    ]
  return {
    label,
    id: label,
  }
})

export const transactionMoveFundsTypeEnumLabels: Record<
  TransactionMoveFundsTypeEnum,
  string
> = {
  [TransactionMoveFundsTypeEnum.COMING]: 'Приход',
  [TransactionMoveFundsTypeEnum.SPENDING]: 'Расход',
}
export const transactionMoveFundsTypeEnumOptions: Option[] = Object.keys(
  transactionMoveFundsTypeEnumLabels,
).map((value) => {
  const label =
    transactionMoveFundsTypeEnumLabels[
      value as keyof typeof transactionMoveFundsTypeEnumLabels
    ]
  return {
    label,
    id: value,
  }
})

export const transactionPaymentTypeEnumLabels: Record<
  TransactionPaymentTypeEnum,
  string
> = {
  [TransactionPaymentTypeEnum.CASH]: 'Наличный расчет',
  [TransactionPaymentTypeEnum.NON_CASH]: 'Безналичный расчет',
}

export const transactionPaymentTypeEnumOptions: OptionEnum<TransactionPaymentTypeEnum>[] =
  Object.keys(TransactionPaymentTypeEnum).map((value) => {
    const label =
      transactionPaymentTypeEnumLabels[
        value as keyof typeof TransactionPaymentTypeEnum
      ]
    return {
      label,
      id: value as TransactionPaymentTypeEnum,
    }
  })

export const transactionBalanceTypeEnumLabels: Record<
  TransactionBalanceTypeEnum,
  string
> = {
  [TransactionBalanceTypeEnum.MAIN]: 'Текущий баланс',
  [TransactionBalanceTypeEnum.BY_DEPT]: 'Кредитный баланс',
}
export const transactionBalanceTypeEnumOptions: Option[] = Object.keys(
  TransactionBalanceTypeEnum,
).map((value) => {
  const label =
    transactionBalanceTypeEnumLabels[
      value as keyof typeof TransactionBalanceTypeEnum
    ]
  return {
    label,
    id: value,
  }
})

export const weekDaysEnumLabels: Record<WeekDaysEnum, string> = {
  [WeekDaysEnum.MONDAY]: 'понедельник',
  [WeekDaysEnum.TUESDAY]: 'вторник',
  [WeekDaysEnum.WEDNESDAY]: 'среда',
  [WeekDaysEnum.THURSDAY]: 'четверг',
  [WeekDaysEnum.FRIDAY]: 'пятница',
  [WeekDaysEnum.SATURDAY]: 'суббота',
  [WeekDaysEnum.SUNDAY]: 'воскресенье',
}

export type WeekDayType = {
  id: string
  label: string
  gender: 'male' | 'female' | 'neuter'
}
export const maleDays = [0, 1, 3]
export const femaleDays = [2, 4, 5]

export const weekDaysEnumOptions: WeekDayType[] = Object.keys(WeekDaysEnum).map(
  (value, index) => {
    const label = weekDaysEnumLabels[value as keyof typeof WeekDaysEnum]
    const gender = maleDays.includes(index)
      ? 'male'
      : femaleDays.includes(index)
      ? 'female'
      : 'neuter'
    return {
      id: String(index + 1),
      label,
      gender,
    }
  },
)

export const paymentProcedureEnumLabels: Record<
  RentalContractWorkScheduleEnum,
  string
> = {
  [RentalContractWorkScheduleEnum.FULL_WEEK]: 'Ежедневно',
  [RentalContractWorkScheduleEnum.SIX_THROUGH_ONE]:
    'Ежедневно, кроме выходного',
}

export const appealsStatusEnumLabels: Record<AppealsStatusEnum, string> = {
  [AppealsStatusEnum.NEW]: 'Новое',
  [AppealsStatusEnum.PROCESSING]: 'В работе',
  [AppealsStatusEnum.SOLVED]: 'Решено',
  [AppealsStatusEnum.REJECTED]: 'Отказ',
}

export const AppealsStatusEnumOptions: OptionEnum<AppealsStatusEnum>[] =
  Object.keys(AppealsStatusEnum).map((value) => {
    const label =
      appealsStatusEnumLabels[value as keyof typeof AppealsStatusEnum]
    return {
      label,
      id: value as AppealsStatusEnum,
    }
  })

export const daysEnumLabels: Record<DayEnum, string> = {
  [DayEnum.Monday]: 'Понедельник',
  [DayEnum.Tuesday]: 'Вторник',
  [DayEnum.Wednesday]: 'Среда',
  [DayEnum.Thursday]: 'Четверг',
  [DayEnum.Friday]: 'Пятница',
  [DayEnum.Saturday]: 'Суббота',
  [DayEnum.Sunday]: 'Воскресенье',
}

export const daysEnumOptions: OptionEnum<DayEnum>[] = Object.keys(DayEnum).map(
  (value) => {
    const label = daysEnumLabels[value as keyof typeof DayEnum]
    return {
      label,
      id: value as DayEnum,
    }
  },
)

export const carPropertyTypeEnumLabels: Record<CarPropertyTypeEnum, string> = {
  [CarPropertyTypeEnum.LEASING]: 'Лизинг',
  [CarPropertyTypeEnum.LEASEBACK]: 'Возвратный лизинг',
  [CarPropertyTypeEnum.PROPERTY]: 'Собственность',
}

export const carPropertyTypeEnumOptions: OptionEnum<CarPropertyTypeEnum>[] =
  Object.keys(CarPropertyTypeEnum).map((value) => {
    const label =
      carPropertyTypeEnumLabels[value as keyof typeof CarPropertyTypeEnum]
    return {
      label,
      id: value as CarPropertyTypeEnum,
    }
  })

export const STATUS_VARIANT = {
  green: {
    backgroundColor: myPalette.green['100'],
    color: myPalette.green['600'],
  },
  outlinedGreen: {
    backgroundColor: myPalette.white.main,
    color: myPalette.green['600'],
    border: `1px solid ${myPalette.green['100']}`,
  },
  red: {
    backgroundColor: myPalette.red['100'],
    color: myPalette.red['600'],
  },
  outlinedRed: {
    backgroundColor: myPalette.white.main,
    color: myPalette.red['600'],
    border: `1px solid ${myPalette.red['600']}`,
  },
  gray: {
    backgroundColor: myPalette.gray['100'],
    color: myPalette.gray['700'],
  },
  yellow: {
    backgroundColor: myPalette.yellow['100'],
    color: myPalette.yellow['600'],
  },
  brand: {
    backgroundColor: myPalette.brand['100'],
    color: myPalette.brand['700'],
  },
  blue: {
    backgroundColor: myPalette.blue['100'],
    color: myPalette.blue['600'],
  },
  purple: {
    backgroundColor: myPalette.purple['100'],
    color: myPalette.purple['600'],
  },
}
