import { z } from 'zod'
import {
  TransactionBalanceTypeEnum,
  TransactionMoveFundsTypeEnum,
  TransactionPaymentTypeEnum,
} from '~/shared/config/enums'
import {
  dateSchema,
  enumOptionSchema,
  priceRequiredScheme,
  uuidOptionSchema,
} from '~/shared/lib/schemas'
import { ApiModel } from './core'

const schema = z.object({
  moveFundsType: enumOptionSchema(TransactionMoveFundsTypeEnum),
  balanceType: enumOptionSchema(TransactionBalanceTypeEnum),
  paymentType: enumOptionSchema(TransactionPaymentTypeEnum),
  appliedAt: dateSchema.optional(),
  driverId: uuidOptionSchema,
  driverFullName: z.string().optional(),
  carId: uuidOptionSchema,
  carPlateNumber: z.string().optional(),
  carSts: z.string().optional(),
  amount: priceRequiredScheme,
  balanceAmount: z.number().optional(),
  flowStatementId: uuidOptionSchema,
  flowStatementName: z.string().optional(),
  subdivisionId: uuidOptionSchema,
  subdivisionName: z.string().optional(),
  comment: z.string().min(1, 'Обязательное поле'),
  sourceType: z.string().optional(),
  aggregatorAccountId: z.string().optional(),
  aggregatorAccountTitle: z.string().optional(),
  stornoId: z.string().optional(),
  appealId: z.string().optional(),
  createdAt: dateSchema.optional(),
  updatedAt: dateSchema.optional(),
})

export type BalanceHistoriesAttributes = z.infer<typeof schema>

export class BalanceHistory extends ApiModel<typeof schema> {
  static jsonApiType = 'balance-histories'

  getAppliedAt(): string {
    return this.getAttribute('appliedAt')
  }

  static schema = schema

  getDriverId(): string {
    return this.getAttribute('driverId')
  }

  getDriverFullName(): string {
    return this.getAttribute('driverFullName')
  }

  getCarId(): string {
    return this.getAttribute('carId')
  }

  getCarPlateNumber(): string {
    return this.getAttribute('carPlateNumber')
  }

  getMoveFundsType(): TransactionMoveFundsTypeEnum {
    return this.getAttribute('moveFundsType')
  }

  getBalanceType(): TransactionBalanceTypeEnum {
    return this.getAttribute('balanceType')
  }

  getPaymentType(): TransactionPaymentTypeEnum {
    return this.getAttribute('paymentType')
  }

  getAmount(): number {
    return this.getAttribute('amount')
  }

  getComment(): string {
    return this.getAttribute('comment')
  }

  getFlowStatementId(): string {
    return this.getAttribute('flowStatementId')
  }

  getFlowStatementName(): string {
    return this.getAttribute('flowStatementName')
  }

  getSourceType(): string {
    return this.getAttribute('sourceType')
  }

  getAggregatorAccountId(): string {
    return this.getAttribute('aggregatorAccountId')
  }

  getAggregatorAccountTitle(): string {
    return this.getAttribute('aggregatorAccountTitle')
  }

  getStornoId(): string {
    return this.getAttribute('stornoId')
  }

  static async create(values: Partial<BalanceHistoriesAttributes>) {
    const url = 'balances/actions/apply-transaction'
    return BalanceHistory.effectiveHttpClient.post(url, values)
  }

  static async applyStorno(id: UniqueId) {
    const url = `${BalanceHistory.getJsonApiUrl()}/${id}/actions/apply-storno`
    return BalanceHistory.effectiveHttpClient.post(url)
  }

  static async exportBalanceHistory(filters: string): Promise<Blob> {
    const url = `${BalanceHistory.getJsonApiUrl()}/actions/export?${filters}`
    const client = BalanceHistory.httpClient.getImplementingClient()

    const response = await client.get(url, {
      responseType: 'blob',
    })

    return response?.data
  }
  static async exportDriverBalance(filters: string): Promise<Blob> {
    const url = `${BalanceHistory.getJsonApiUrl()}/actions/export-driver?${filters}`
    const client = BalanceHistory.httpClient.getImplementingClient()

    const response = await client.get(url, {
      responseType: 'blob',
    })

    return response?.data
  }
}
