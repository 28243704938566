import { z } from 'zod'
import { TransactionPaymentTypeEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const schema = z.object({
  amount: z.number(),
  createdAt: dateSchema.optional(),
  updatedAt: dateSchema.optional(),
})

export type BalanceAttributes = z.infer<typeof schema>

export type MassImportSaveValues = {
  importId: UniqueId
  flowStatementId: UniqueId
  paymentType: TransactionPaymentTypeEnum
}

export class Balance extends ApiModel<typeof schema> {
  static jsonApiType = 'balances'

  getAmount(): number {
    return this.getAttribute('amount')
  }

  static getMassImportTemplate() {
    return `${Balance.getJsonApiUrl()}/actions/mass-import-template`
  }

  static async massImport(file: File): Promise<{ importId: UniqueId }> {
    const url = `${Balance.getJsonApiUrl()}/actions/mass-import`

    const data = new FormData()
    data.append('transactionImport', file)
    const client = Balance.httpClient.getImplementingClient()

    const response = await client.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response?.data
  }

  static async massImportSave(values: MassImportSaveValues) {
    const url = `${Balance.getJsonApiUrl()}/actions/mass-import-save`

    const client = Balance.httpClient.getImplementingClient()

    await client.post(url, values)
  }
}
