import { GridColDef } from '@mui/x-data-grid'
import { paymentProcedureEnumLabels } from '~/shared/config/constants'
import { ColumnDate, ColumnValue } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: '№',
    field: 'serialNumber',
    sortable: false,
    width: 100,
  },
  {
    headerName: 'Порядок списаний',
    field: 'paymentOrder',
    sortable: false,
    renderCell: ({ row }) => (
      <ColumnValue>
        {row.workSchedule && paymentProcedureEnumLabels[row.workSchedule]}
      </ColumnValue>
    ),
    flex: 1,
  },
  {
    headerName: 'Время списания',
    field: 'time',
    sortable: false,
    renderCell: ({ row }) => <ColumnDate type='moscowTime' value={row.time} />,
    width: 150,
  },
  {
    headerName: 'Сумма',
    field: 'cost',
    sortable: false,
    flex: 1,
  },
]
