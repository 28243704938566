import { useGate, useStore, useStoreMap } from 'effector-react'
import { aggregatorDriverModel } from '~/entities/Driver/Aggregator'
import { YandexForm } from '~/entities/Driver/Aggregator/ui/Form/YandexForm'
import { usePermissions } from '~/entities/viewer'
import {
  Gate,
  formSubmitted,
  aggregatorDriverUpdateFx,
  $aggregatorDriver,
} from './model'

type EditYandexAggregatorProps = {
  aggregatorId: string
}
export function EditYandexAggregator({
  aggregatorId,
}: EditYandexAggregatorProps) {
  useGate(Gate, { aggregatorId })
  const { canDriverUpdateAggregator } = usePermissions()

  const isSubmitting = useStore(aggregatorDriverUpdateFx.pending)
  const isLoading = useStore(aggregatorDriverModel.requestFx.pending)

  const accountId = useStoreMap($aggregatorDriver, (aggregatorDriver) =>
    aggregatorDriver?.getAggregatorAccount()?.getApiId(),
  )

  const defaultValues = useStoreMap($aggregatorDriver, (aggregatorDriver) => {
    const yandexWorkRule = aggregatorDriver?.getYandexWorkRule()
    const aggregatorOccupation = aggregatorDriver?.getAggregatorOccupation()
    return {
      ...aggregatorDriver?.getAttributes(),
      yandexWorkRuleOption: yandexWorkRule ? yandexWorkRule.getOption() : null,
      aggregatorOccupationOption: aggregatorOccupation
        ? aggregatorOccupation.getOption()
        : null,
    }
  })

  return (
    <YandexForm
      accountId={accountId}
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSubmitting}
      canEdit={canDriverUpdateAggregator}
    />
  )
}
