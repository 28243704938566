import { CircularProgress } from '@mui/joy'
import { ReactNode, useCallback, useState } from 'react'
import { Media } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { Button } from '~/shared/ui/Buttons'
import { DownloadIcon } from '~/shared/ui/Icons'
import { MenuItem } from './styled'

type FileExportLinkProps = {
  title: string
  fnGetLink: () => string
  show?: boolean
  variant?: 'menuItem'
  startDecorator?: ReactNode
}

export function FileExportLink({
  title,
  show = true,
  fnGetLink,
  variant,
  startDecorator,
}: FileExportLinkProps) {
  const [isLoading, setIsLoading] = useState<boolean>()

  const handleMediaGet = useCallback(async () => {
    try {
      setIsLoading(true)
      const url = fnGetLink()
      const blob = await Media.getMedia(url)

      window.open(URL.createObjectURL(blob), '_self')
    } catch (e) {
      logger.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [fnGetLink])

  if (!show) return null

  if (variant === 'menuItem') {
    return (
      <MenuItem onClick={handleMediaGet}>
        {startDecorator || <DownloadIcon />}
        {title}
        {isLoading && <CircularProgress size='sm' />}
      </MenuItem>
    )
  }

  return (
    <Button
      variant='brand'
      onClick={handleMediaGet}
      startDecorator={startDecorator || <DownloadIcon />}
      loading={isLoading}
    >
      {title}
    </Button>
  )
}
