import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { YandexDashboard } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const domain = createDomain('features.dashboards')

export const Gate = createGate()

export const requestFx = domain.createEffect({
  handler: () => {
    return fetchDashboards()
  },
})

sample({
  clock: Gate.open,
  target: requestFx,
})

sample({
  clock: requestFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получение графиков',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $dashboards = domain
  .createStore<YandexDashboard[] | null>(null)
  .on(requestFx.doneData, (_, dashboards) => dashboards)
  .on(Gate.close, () => null)

export async function fetchDashboards() {
  const response = await YandexDashboard.limit(50).get(1)

  return response.getData() as YandexDashboard[]
}
