import { usePermissions } from '~/entities/viewer'
import { canShowObject } from '~/shared/lib/utils'

type Header = { label: string; to: string; parent?: string }

export const useHeader = (): Header[] => {
  const {
    canCarsView,
    canDriversView,
    canWorkRulesView,
    canBalanceSectionView,
    canCarOrganizationsView,
    canTaxiparkSectionView,
  } = usePermissions()

  return [
    ...canShowObject(canCarsView, {
      label: 'Автомобили',
      to: '/cars',
    }),
    ...canShowObject(canDriversView, {
      label: 'Водители',
      to: '/drivers',
    }),
    ...canShowObject(canBalanceSectionView, {
      label: 'Баланс',
      to: '/balance/balance-histories',
      parent: '/balance',
    }),
    ...canShowObject(canWorkRulesView, {
      label: 'Условия работы',
      to: '/work-rules',
    }),
    ...canShowObject(canTaxiparkSectionView, {
      label: 'Таксопарк',
      to: '/taxipark/information',
      parent: '/taxipark',
    }),
    ...canShowObject(canCarOrganizationsView, {
      label: 'Организации',
      to: '/organizations/car-rentals',
      parent: '/organizations',
    }),
    ...canShowObject(canCarOrganizationsView, {
      label: 'Дашборды',
      to: '/dashboards',
      parent: '/dashboards',
    }),
  ]
}
