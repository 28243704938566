import { useStore, useStoreMap } from 'effector-react'
import { carModel, Form } from 'src/entities/Car'
import { usePermissions } from '~/entities/viewer'
import {
  carPropertyTypeEnumLabels,
  fuelTypeEnumLabels,
  transmissionEnumLabels,
} from '~/shared/config/constants'

import { formSubmitted, sendFormFx, $car, $id } from './model'

export function EditCar() {
  const { canCarsUpdate } = usePermissions()
  const isSaving = useStore(sendFormFx.pending)
  const isLoading = useStore(carModel.requestFx.pending)
  const carId = useStore($id)

  const defaultValues = useStoreMap($car, (car) => {
    const transmission = car?.getTransmission()
    const fuelType = car?.getFuelType()
    const propertyType = car?.getPropertyType()
    return {
      ...car?.getAttributes(),
      modelId: car?.getCarModel()?.getOption(),
      brand: car?.getCarModel()?.getCarBrand()?.getOption(),
      colorId: car?.getColor()?.getOption(),
      subdivisionId: car?.getSubdivision()?.getOption(),
      vehicleCategoryId: car?.getVehicleCategory()?.getOption(),
      carRentalId: car?.getCarRental()?.getOption(),
      regionId: car?.getRegion()?.getOption(),
      aggregatorAccountOption: car?.getAggregatorAccount()?.getOption(),
      estimatedCost: car?.getEstimatedCost(),
      telematicAccountOption: car?.getTelematicAccount()?.getOption() || null,
      transmission: transmission
        ? {
            id: transmission,
            label: transmissionEnumLabels[transmission],
          }
        : null,
      fuelType: fuelType
        ? {
            id: fuelType,
            label: fuelTypeEnumLabels[fuelType],
          }
        : null,
      propertyType: propertyType
        ? {
            id: propertyType,
            label: carPropertyTypeEnumLabels[propertyType],
          }
        : null,
    }
  })

  return (
    <Form
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={canCarsUpdate}
      isLoading={isLoading}
      isView
      carId={carId}
    />
  )
}
