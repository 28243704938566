import { Stack, Typography } from '@mui/joy'
import { useGate, useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { driverModel } from '~/entities/Driver'
import { priceFormat } from '~/shared/lib/utils'
import { ChipStatus } from '~/shared/ui/Chips'
import { Gate } from './model'

export function MainBalance() {
  useGate(Gate)
  const isLoading = useStore(driverModel.requestDriverFx.pending)
  const mainBalance = useStoreMap(
    driverModel.$driver,
    (driver) => driver?.getMainBalance()?.getAmount() || 0,
  )

  const amount = useMemo(
    () => Number(String(mainBalance).replace(/-/g, '')),
    [mainBalance],
  )

  const options = useMemo(() => {
    if (mainBalance < 0) return { variant: 'red', prefix: '-' }
    if (mainBalance > 0) return { variant: 'green', prefix: '+' }

    return { variant: 'gray', prefix: '' }
  }, [mainBalance])

  if (isLoading) return null

  return (
    <Stack
      data-testid='current-balance'
      direction='row'
      alignItems='center'
      spacing={1}
    >
      <Typography fontSize={12}>Текущий баланс</Typography>

      <ChipStatus
        label={`${options.prefix}${priceFormat(amount)} руб.`}
        variant={options?.variant}
      />
    </Stack>
  )
}
