import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { dashboardsModel } from '~/features/Dashboards'
import { YandexDashboard } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('features.dashboards.iframeContent')

export const Gate = createGate<{ id: UniqueId }>()

export const requestTokenFx = domain.createEffect<
  UniqueId,
  { token: string; id: UniqueId }
>({
  handler: async (id) => {
    const { token } = await YandexDashboard.getToken(id)
    return { id, token }
  },
})

export const $id = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { id }) => id)
  .on(dashboardsModel.Gate.close, () => null)

export const $tokenById = domain
  .createStore<Record<UniqueId, string> | null>(null)
  .on(requestTokenFx.doneData, (store, { token, id }) => ({
    ...store,
    [id]: token,
  }))
  .on(dashboardsModel.Gate.close, () => null)

sample({
  clock: $id,
  filter: isString,
  target: requestTokenFx,
})

sample({
  clock: requestTokenFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получение графика',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
