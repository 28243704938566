import { Element } from 'react-scroll'
import { DiagnosticCard } from '~/features/Сars/Documents/ui/DiagnosticCard'
import { KaskoDocument } from '~/features/Сars/Documents/ui/KaskoDocument'
import { OsagoDocument } from '~/features/Сars/Documents/ui/OsagoDocument'
import { OsgopDocument } from '~/features/Сars/Documents/ui/OsgopDocument'
import { OtherDocument } from '~/features/Сars/Documents/ui/OtherDocument'
import { PtsDocument } from '~/features/Сars/Documents/ui/PtsDocument'
import { StsDocument } from '~/features/Сars/Documents/ui/StsDocument'
import { TaxiLicense } from '~/features/Сars/Documents/ui/TaxiLicense'
import { Container } from '~/shared/ui/Layout'

export const Documents = () => {
  return (
    <>
      <Element name='pts'>
        <Container data-testid='container-pts-documents'>
          <PtsDocument />
        </Container>
      </Element>

      <Element name='sts'>
        <Container data-testid='container-sts-documents'>
          <StsDocument />
        </Container>
      </Element>

      <Element name='taxiLicense'>
        <Container data-testid='container-taxi-license-documents'>
          <TaxiLicense />
        </Container>
      </Element>

      <Element name='osago'>
        <Container data-testid='container-osago-documents'>
          <OsagoDocument />
        </Container>
      </Element>

      <Element name='kasko'>
        <Container data-testid='container-kasko-documents'>
          <KaskoDocument />
        </Container>
      </Element>

      <Element name='dc'>
        <Container data-testid='container-dc-documents'>
          <DiagnosticCard />
        </Container>
      </Element>

      <Element name='osgop'>
        <Container data-testid='container-osgop-documents'>
          <OsgopDocument />
        </Container>
      </Element>

      <Element name='otherDocument'>
        <Container>
          <OtherDocument />
        </Container>
      </Element>
    </>
  )
}
