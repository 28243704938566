import { Grid } from '@mui/joy'
import { MaskInput, TextareaInput, TextInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type ExtraProps = {
  disabled?: boolean
  skeletonShow?: boolean
}

export function Extra({ disabled, skeletonShow }: ExtraProps) {
  return (
    <Container>
      <ContainerTitle>Дополнительная информация</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <TextInput
            label='Фактический адрес проживания'
            name='placeOfResidence'
            placeholder='Введите фактический адрес проживания'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Отзыв'
            name='data.comment'
            placeholder='Введите отзыв'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Последнее место работы'
            name='data.lastJob'
            placeholder='Введите последнее место работы'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Позывной'
            name='data.callsign'
            placeholder='Введите позывной'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='ИНН'
            name='inn'
            placeholder='Введите ИНН'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={6}>
          <MaskInput
            label='СНИЛС'
            name='snils'
            placeholder='Введите СНИЛС'
            readOnly={disabled}
            skeletonShow={skeletonShow}
            mask='###-###-###-##'
          />
        </Grid>
        <Grid xs={6}>
          <MaskInput
            label='ОГРНИП'
            name='ogrnip'
            placeholder='Введите ОГРНИП'
            readOnly={disabled}
            skeletonShow={skeletonShow}
            mask='#-##-##-#########-#'
          />
        </Grid>

        <Grid xs={12}>
          <TextareaInput
            label='Комментарий'
            name='comment'
            placeholder='Введите комментарий'
            sx={{
              height: '138px',
            }}
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
