import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Button } from '~/shared/ui/Buttons'
import { FormProvider, TextareaInput } from '~/shared/ui/Form'

const formSchema = z.object({
  comment: z.string().min(1, 'Обязательное поле'),
})

type FormValues = {
  comment: string
}

type FormProps = {
  onCancel: () => void
  onSuccess: (formValues: FormValues) => void
  isSubmitting: boolean
}

export function Form({ onCancel, onSuccess, isSubmitting }: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <TextareaInput
        label='Комментарий'
        name='comment'
        placeholder='Введите комментарий'
        readOnly={isSubmitting}
        sx={{
          height: '138px',
        }}
      />
      <br />
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Button
          variant='gray'
          onClick={onCancel}
          disabled={isSubmitting}
          fullWidth
        >
          Отмена
        </Button>
        <Button variant='red' type='submit' loading={isSubmitting} fullWidth>
          Отказать
        </Button>
      </Stack>
    </FormProvider>
  )
}
