import { createDomain, sample } from 'effector'
import { carFormModel } from 'src/entities/Car'
import { AxiosErrorType, Car } from '~/shared/api'
import { pushFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

import { isString } from '~/shared/lib/utils'

export const domain = createDomain('features.car.create')

export const createFx = domain.createEffect<
  carFormModel.FormValues,
  Car,
  AxiosErrorType
>({
  async handler(values) {
    const {
      colorId,
      modelId,
      subdivisionId,
      vehicleCategoryId,
      carRentalId,
      regionId,
      aggregatorAccountOption,
      telematicAccountOption,
      fuelType,
      transmission,
      propertyType,
      ...attrs
    } = values

    const car = new Car({
      ...attrs,
      ...(isString(fuelType) && { fuelType }),
      ...(isString(transmission) && { transmission }),
      ...{ propertyType: isString(propertyType) ? propertyType : null },
    })

    if (isString(colorId)) car.setColor(colorId)
    if (isString(modelId)) car.setCarModel(modelId)
    if (isString(subdivisionId)) car.setSubdivision(subdivisionId)
    if (isString(vehicleCategoryId)) car.setVehicleCategory(vehicleCategoryId)
    if (isString(carRentalId)) car.setCarRental(carRentalId)
    if (isString(regionId)) car.setRegion(regionId)
    if (isString(telematicAccountOption)) {
      car.setTelematicAccount(telematicAccountOption)
    }
    if (isString(aggregatorAccountOption)) {
      car.setAggregatorAccount(aggregatorAccountOption)
    }

    await car.save()
    return car
  },
})
sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Автомобиль создан',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const formSubmitted = domain.createEvent<carFormModel.FormValues>()
sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn(car) {
    return `/cars/${car.getApiId()}`
  },
  target: pushFx,
})
