import DeleteIcon from '@mui/icons-material/Delete'
import { Grid, Stack } from '@mui/joy'
import { ThumbType } from '~/shared/api'
import { myPalette } from '~/shared/lib/theme'
import { IconButton } from '~/shared/ui/Buttons'
import { Img } from '~/shared/ui/Img'
import { FileWrapper, Item } from '../styled'
import { OpenThumb } from './OpenThumb'

type ThumbsProps = {
  onDelete?: (id: UniqueId) => void
  canDelete?: boolean
  thumbs: ThumbType[]
}

export function Thumbs({ onDelete, canDelete = true, thumbs }: ThumbsProps) {
  return (
    <Grid container spacing={2.5}>
      {thumbs.map((thumb) => (
        <Grid xs={4} key={`${thumb.preview}-${thumb.id}`}>
          <Item>
            <FileWrapper>
              {thumb.preview && <Img url={thumb.preview} />}
            </FileWrapper>

            <Stack direction='row' spacing={1}>
              <OpenThumb url={thumb.src} />
              {canDelete && (
                <IconButton
                  onClick={() => onDelete?.(thumb.id)}
                  tooltipProps={{ title: 'Удалить' }}
                  loading={thumb.pending}
                >
                  <DeleteIcon sx={{ color: myPalette.red['700'] }} />
                </IconButton>
              )}
            </Stack>
          </Item>
        </Grid>
      ))}
    </Grid>
  )
}
