import { uniqBy } from 'lodash'
import { CarModel, CarStatuses, Region, Subdivision } from '~/shared/api'
import {
  carAtWorkSubStatusEnumOptions,
  carPropertyTypeEnumOptions,
  carUnderRepairSubStatusEnumOptions,
  fuelTypeEnumOptions,
} from '~/shared/config/constants'
import { Filter } from '~/shared/ui/AsyncGrid'

export const filters: Filter[] = [
  {
    field: 'vin',
    label: 'VIN',
    type: 'text',
  },
  {
    field: 'plateNumber',
    label: 'Гос. номер',
    type: 'text',
  },
  {
    field: 'regionId',
    label: 'Регион',
    type: 'autocompleteMultiple',
    fetchOptions: Region.fetchOptions,
  },
  {
    field: 'subdivisionId',
    label: 'Подразделение',
    type: 'autocompleteMultiple',
    fetchOptions: Subdivision.fetchOptions,
  },
  {
    field: 'status',
    label: 'Статус',
    type: 'autocompleteMultiple',
    fetchOptions: CarStatuses.fetchOptions,
    canAsyncSearch: false,
  },
  {
    field: 'subStatus',
    label: 'Подстатус',
    type: 'selectMultiple',
    options: uniqBy(
      [...carUnderRepairSubStatusEnumOptions, ...carAtWorkSubStatusEnumOptions],
      'id',
    ),
  },
  {
    field: 'carModelId',
    label: 'Модель',
    type: 'autocomplete',
    fetchOptions: CarModel.fetchOptions,
  },
  {
    field: 'fuelType',
    label: 'Вид топлива',
    type: 'selectMultiple',
    options: fuelTypeEnumOptions,
  },
  {
    field: 'propertyType',
    label: 'Тип собственности',
    type: 'select',
    options: carPropertyTypeEnumOptions,
  },
  {
    field: 'isLeasingFullyPaid',
    label: 'Выкуп',
    type: 'boolean',
  },
  {
    field: 'isPromotion',
    label: 'Участвует в акции',
    type: 'boolean',
  },
  {
    field: 'isPreTotaled',
    label: 'ПредТотал',
    type: 'boolean',
  },
]
