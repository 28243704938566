import { List, ListItem } from '@mui/joy'
import { Media } from '~/shared/api'
import { myPalette } from '~/shared/lib/theme'
import { IconButton } from '~/shared/ui/Buttons'
import { FileExportBlob } from '~/shared/ui/FileExport'
import { FileIcon } from '~/shared/ui/Icons'

type FileType = {
  id: UniqueId
  name: string
  filepath: string
}
type ColumnFileListProps = {
  files?: FileType[]
}

export function ColumnFileList({ files }: ColumnFileListProps) {
  if (!files?.length) return null

  return (
    <IconButton
      tooltipProps={{
        placement: 'left',
        keepMounted: true,
        title: (
          <List>
            {files.map((file) => (
              <ListItem
                key={file.id}
                sx={{
                  '.MuiButton-root': {
                    fontWeight: 500,
                    border: 'none',
                    fontSize: '12px',
                  },
                }}
              >
                <FileExportBlob
                  title={file.name}
                  fn={() => Media.getMedia(file.filepath)}
                  target='_blank'
                />
              </ListItem>
            ))}
          </List>
        ),
      }}
    >
      <FileIcon color={myPalette.brand['600']} />
    </IconButton>
  )
}
