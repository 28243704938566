import { Media } from '~/shared/api'
import { FileExportBlob } from '~/shared/ui/FileExport'

type PrintProps = {
  filepath: string
}

export function Print({ filepath }: PrintProps) {
  const fn = () => Media.getMedia(filepath)

  return (
    <FileExportBlob
      target='_blank'
      fn={fn}
      isIcon
      title='Распечатать договор'
    />
  )
}
