import * as z from 'zod'
import {
  AggregatorAccount,
  YandexWorkRule,
  AggregatorOccupation,
} from '~/shared/api'
import { ApiModel, ToOneRelation } from '~/shared/api/core'
import { Option } from '~/shared/config/constants'
import { priceRequiredScheme, uuidOptionSchema } from '~/shared/lib/schemas'

const relationsSchema = z.object({
  yandexWorkRuleOption: uuidOptionSchema,
  aggregatorOccupationOption: uuidOptionSchema,
})

const schema = z
  .object({
    accountId: z.string(),
    driverId: z.string(),
    extId: z.string(),
    mainAccount: z.boolean(),
    selfemployed: z.boolean(),
    balanceLimit: priceRequiredScheme,
    blockOrdersOnBalanceBelowLimit: z.boolean().nullable(),
    partner: z.boolean().nullable(),
    platform: z.boolean().nullable(),
  })
  .merge(relationsSchema)

export type AggregatorDriverAttributes = z.infer<typeof schema>

export class AggregatorDriver extends ApiModel<typeof schema> {
  static jsonApiType = 'aggregator-drivers'

  static schema = schema

  getSelfemployed(): boolean {
    return this.getAttribute('selfemployed')
  }

  getDriverId(): UniqueId {
    return this.getAttribute('driverId')
  }

  aggregatorAccount(): ToOneRelation<AggregatorAccount, this> {
    return this.hasOne(AggregatorAccount)
  }
  getAggregatorAccount(): AggregatorAccount {
    return this.getRelation('aggregatorAccount')
  }

  yandexWorkRule(): ToOneRelation<YandexWorkRule, this> {
    return this.hasOne(YandexWorkRule)
  }
  getYandexWorkRule(): YandexWorkRule {
    return this.getRelation('yandexWorkRule')
  }
  setYandexWorkRule(id: UniqueId) {
    const yandexWorkRule = new YandexWorkRule()
    yandexWorkRule.setApiId(id)
    this.setRelation('yandexWorkRule', yandexWorkRule)
  }

  aggregatorOccupation(): ToOneRelation<AggregatorOccupation, this> {
    return this.hasOne(AggregatorOccupation)
  }
  getAggregatorOccupation(): AggregatorOccupation {
    return this.getRelation('aggregatorOccupation')
  }
  setAggregatorOccupation(id: UniqueId) {
    const aggregatorOccupation = new AggregatorOccupation()
    aggregatorOccupation.setApiId(id)
    this.setRelation('aggregatorOccupation', aggregatorOccupation)
  }

  static async setMain(aggregatorDriverId: UniqueId) {
    const url = `${AggregatorDriver.getJsonApiUrl()}/actions/set-main`
    const client = AggregatorAccount.httpClient.getImplementingClient()
    return await client.post(url, {
      aggregatorDriverId,
    })
  }

  getOption(): Option {
    return {
      id: this.getApiId() as UniqueId,
      label: `${this.getAggregatorAccount().getTitle()}${
        this.getSelfemployed() ? ' - Самозанятый' : ''
      }`,
    }
  }

  static async fetchOptionsByDriverId(driverId: UniqueId) {
    const response = await AggregatorDriver.with('aggregatorAccount')
      .where('driverId', driverId)
      .get(1)
    return response.getData().map((o) => o.getOption())
  }
}
