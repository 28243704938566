import { LinearProgress } from '@mui/joy'
import { useGate, useStore, useStoreMap } from 'effector-react'
import { dashboardsModel } from '~/features/Dashboards'
import { IframeContent } from '~/features/Dashboards/IframeContent'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import RoutesTabs from '~/shared/ui/RoutesTabs'

export default function DashboardsPage() {
  useGate(dashboardsModel.Gate)

  const isDashboardsPending = useStore(dashboardsModel.requestFx.pending)

  const configTabs = useStoreMap(dashboardsModel.$dashboards, (dashboards) =>
    (dashboards || []).map((dashboard) => {
      const attr = dashboard.getAttributes()
      return {
        label: attr.title,
        path: `/${attr.id}`,
        content: <IframeContent id={attr.id} />,
      }
    }),
  )

  const headerExtra = (
    <Container isHeader>
      <PageTitle>Дашборды</PageTitle>
    </Container>
  )

  return (
    <PageContainer title='Дашборды'>
      {isDashboardsPending && (
        <>
          {headerExtra}
          <Container>
            <LinearProgress size='lg' />
          </Container>
        </>
      )}

      {!isDashboardsPending && Boolean(configTabs?.length) && (
        <RoutesTabs
          parentPath='/dashboards'
          config={configTabs}
          parentContent={headerExtra}
        />
      )}

      {!isDashboardsPending && !configTabs?.length && (
        <>
          {headerExtra}
          <NotFound message='Графики не найдены' />
        </>
      )}
    </PageContainer>
  )
}
