import { createDomain, sample } from 'effector'
import { useStore } from 'effector-react'
import jwtDecode from 'jwt-decode'
import { authStore, http, User, userOptionsStore } from '~/shared/api'
import { UserOptionsMe } from '~/shared/api/core/store/types'
import { pushFx } from '~/shared/lib/history'
import { sentryModel } from '~/shared/lib/sentry'
import { isString } from '~/shared/lib/utils'
import { DecodedToken } from './types'
export const domain = createDomain('entities.viewer')

/* Save token */
export const tokenSaved = domain.createEvent<string>()
export const saveTokenFx = domain.createEffect<string, void>({
  async handler(token) {
    authStore.saveToken(token)
  },
})
sample({
  clock: tokenSaved,
  target: saveTokenFx,
})

/* Clear token */
export const tokenCleared = domain.createEvent()
export const clearTokenFx = domain.createEffect({
  async handler() {
    authStore.clearToken()
  },
})
sample({
  clock: tokenCleared,
  target: clearTokenFx,
})

/* Save user options */
export const userOptionsSave = domain.createEvent<UserOptionsMe>()
export const saveUserOptionsFx = domain.createEffect<UserOptionsMe, void>({
  async handler(options) {
    userOptionsStore.save(options)
  },
})
sample({
  clock: userOptionsSave,
  target: saveUserOptionsFx,
})

/* Clear user options */
export const userOptionsCleared = domain.createEvent()
export const clearUserOptionsFx = domain.createEffect({
  async handler() {
    userOptionsStore.clear()
  },
})
sample({
  clock: userOptionsCleared,
  target: clearUserOptionsFx,
})

http.onUnauthorize(() => {
  tokenCleared()
  userOptionsCleared()
})

/* Token */
export const $token = domain
  .createStore<string | null>(authStore.token)
  .on(tokenSaved, (_, token) => token)
  .on(tokenCleared, () => null)
export const $decodedToken = $token.map((token) => {
  try {
    return jwtDecode<DecodedToken>(token as string)
  } catch (_) {
    return null
  }
})
export const $authenticated = $decodedToken.map<boolean>(
  (token) => token !== null,
)
export const $id = $decodedToken.map((decodedToken) => decodedToken?.sub)
export const $email = $decodedToken.map((decodedToken) => decodedToken?.email)

$email.watch((email) => {
  sentryModel.setUser(email)
})

/* Sign-in/Sign-out */
export const signIn = domain.createEvent()
sample({
  clock: signIn,
  fn() {
    return '/login'
  },
  target: pushFx,
})

export const signOut = domain.createEvent()
sample({
  clock: signOut,
  target: [tokenCleared, userOptionsCleared, sentryModel.clearUser],
})

/* User data */
export const fetchUserFx = domain.createEffect<UniqueId, User | null>({
  async handler(id) {
    const response = await User.with('regions').with('roles').find(id)
    return response.getData() as User
  },
})

export const userRequested = domain.createEvent()
sample({
  clock: userRequested,
  source: $id,
  filter: isString,
  target: fetchUserFx,
})

export const $isUserLoading = fetchUserFx.pending
export const $user = domain
  .createStore<User | null>(null)
  .on([fetchUserFx.doneData], (_, user) => user)

/* Hooks */
export const useAuthenticated = () => useStore($authenticated)
