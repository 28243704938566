import { zodResolver } from '@hookform/resolvers/zod'
import { CircularProgress, Grid } from '@mui/joy'
import { useGate, useStore } from 'effector-react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  transactionPaymentTypeEnumOptions,
  transactionBalanceTypeEnumOptions,
  transactionMoveFundsTypeEnumOptions,
} from '~/shared/config/constants'
import {
  TransactionBalanceTypeEnum,
  TransactionMoveFundsTypeEnum,
} from '~/shared/config/enums'
import { getObject } from '~/shared/lib/utils'
import { LinkDecorator } from '~/shared/ui/Buttons'
import {
  AsyncAutocompleteInput,
  AutocompleteInput,
  FormButtons,
  FormProvider,
  NumericInput,
  TextInput,
  TextareaInput,
} from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import {
  fetchAggregatorAccountByCarOptionFx,
  fetchCarOptions,
  fetchDriverOptions,
  fetchFlowStatementOptions,
  FormGate,
  formSchema,
  FormValues,
} from './model'
import { BalanceUseFormWatchOptions, useFormWatch } from './useFormWatch'

type FormProps = {
  defaultValues?: Partial<FormValues>
  onSuccess?: (formValues: FormValues) => void
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  isReadOnlyDriver?: boolean
  isReadOnlyCar?: boolean
  formWatchOption?: BalanceUseFormWatchOptions
}

export function Form({
  defaultValues,
  onSuccess,
  isReadOnlyDriver,
  isReadOnlyCar,
  formWatchOption,
  isCreating,
  isSubmitting,
  isLoading,
}: FormProps) {
  useGate(FormGate)

  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
  })

  const disabled = !isCreating || isSubmitting

  const { watch } = form

  const moveFundsType = watch('moveFundsType')
  const moveFundsTypeId = getObject(moveFundsType)?.id

  const driverOptionId = getObject(defaultValues?.driverOption)?.id
  const carOptionId = getObject(defaultValues?.driverOption)?.carOption?.id

  const isAggregatorAccountLoading = useStore(
    fetchAggregatorAccountByCarOptionFx.pending,
  )

  useFormWatch(form, formWatchOption)

  const transactionBalanceOptions = useMemo(
    () =>
      isCreating
        ? transactionBalanceTypeEnumOptions.filter(
            ({ id }) => id !== TransactionBalanceTypeEnum.BY_DEPT,
          )
        : transactionBalanceTypeEnumOptions,
    [isCreating],
  )

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Container>
        <ContainerTitle>Оплата</ContainerTitle>
        <Grid container spacing={2.5}>
          <Grid xs={4}>
            <AutocompleteInput
              name='moveFundsType'
              label='Вид движения'
              options={transactionMoveFundsTypeEnumOptions}
              disableClearable
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={4}>
            <NumericInput
              name='amount'
              label='Сумма'
              placeholder='Введите сумму'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={4}>
            <AutocompleteInput
              label='Способ оплаты'
              name='paymentType'
              options={transactionPaymentTypeEnumOptions}
              disableClearable
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <ContainerTitle>Информация о транзакции</ContainerTitle>
        <Grid container spacing={2.5}>
          <Grid xs={4}>
            <AsyncAutocompleteInput
              label='Водитель'
              placeholder='Выбрать'
              name='driverOption'
              fetchOptions={fetchDriverOptions}
              readOnly={isReadOnlyDriver || disabled}
              skeletonShow={isLoading}
              endDecorator={
                disabled &&
                driverOptionId && (
                  <LinkDecorator
                    tooltipText='Перейти в карточку водителя'
                    to={`/drivers/${driverOptionId}`}
                  />
                )
              }
            />
          </Grid>
          <Grid xs={4}>
            <AsyncAutocompleteInput
              label='Автомобиль'
              name='carOption'
              placeholder='Выбрать'
              fetchOptions={fetchCarOptions}
              readOnly={isReadOnlyCar || disabled}
              skeletonShow={isLoading}
              endDecorator={
                disabled &&
                carOptionId && (
                  <LinkDecorator
                    tooltipText='Перейти в карточку автомобиля'
                    to={`/cars/${carOptionId}`}
                  />
                )
              }
            />
          </Grid>
          <Grid xs={4}>
            <TextInput
              label='Аккаунт агрегатора'
              placeholder='Аккаунт агрегатора водителя'
              name='aggregatorAccount'
              readOnly
              skeletonShow={isLoading}
              endDecorator={
                isAggregatorAccountLoading ? (
                  <CircularProgress size='sm' />
                ) : null
              }
            />
          </Grid>
          <Grid xs={6} container>
            <Grid xs={12}>
              <AsyncAutocompleteInput
                label='Статья оборотов'
                name='flowStatementOption'
                placeholder='Выбрать'
                disableClearable
                fetchOptions={() =>
                  fetchFlowStatementOptions(
                    moveFundsTypeId === TransactionMoveFundsTypeEnum.COMING,
                  )
                }
                queryKey={moveFundsTypeId ? [moveFundsTypeId] : []}
                skeletonShow={isLoading}
                readOnly={disabled}
                canAsyncSearch={false}
              />
            </Grid>
            <Grid xs={12}>
              <AutocompleteInput
                label='Раздел баланса'
                name='balanceType'
                options={transactionBalanceOptions}
                disableClearable
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
          </Grid>
          <Grid xs={6}>
            <TextareaInput
              label='Комментарий'
              name='comment'
              placeholder='Введите комментарий'
              minRows={4}
              maxRows={4}
              skeletonShow={isLoading}
              readOnly={disabled}
              sx={{
                height: '138px',
              }}
            />
          </Grid>
        </Grid>
      </Container>

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
