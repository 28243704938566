import { z } from 'zod'
import { ApiModel } from './core'

const schema = z.object({
  id: z.string(),
  title: z.string(),
})

export type YandexDashboardAttributes = z.infer<typeof schema>

export class YandexDashboard extends ApiModel<typeof schema> {
  static jsonApiType = 'yandex-dashboards'

  static schema = schema

  static async getToken(id: UniqueId): Promise<{ token: string }> {
    const url = `${YandexDashboard.getJsonApiUrl()}/${id}/actions/generate-token`
    const response = await YandexDashboard.effectiveHttpClient.get(url)
    return response.getData()
  }
}
