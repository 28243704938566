import * as Sentry from '@sentry/react'
import { createDomain, sample } from 'effector'

export const domain = createDomain('shared.lib.sentryModel')

export const setUser = domain.createEvent<string | undefined>()
export const setUserFx = domain.createEffect<string | undefined, void>({
  async handler(email) {
    if (!email) return
    Sentry.setUser({ email })
  },
})

sample({
  clock: setUser,
  target: setUserFx,
})

export const clearUser = domain.createEvent()
export const clearUserFx = domain.createEffect({
  async handler() {
    Sentry.setUser({ email: '-' })
  },
})

sample({
  clock: clearUser,
  target: clearUserFx,
})
