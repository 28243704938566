import { z } from 'zod'
import { OsgopDocument } from '~/shared/api'
import { Option } from '~/shared/config/constants'

export const formSchema = OsgopDocument.schema.pick({
  number: true,
  issuedDate: true,
  expirationDate: true,
  counterpartyId: true,
  carLicenseeOption: true,
  premium: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = Omit<
  TypeFormSchema,
  'counterpartyId' | 'carLicenseeOption'
> & {
  counterpartyId: UniqueId | Option | null
  carLicenseeOption: UniqueId | Option | null
}
