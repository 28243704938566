import { z } from 'zod'
import { CarLicensee } from '~/shared/api/carLicensee'
import { Counterparty } from '~/shared/api/counterparty'
import { StsDocumentAttributes } from '~/shared/api/stsDocument'
import {
  dateSchema,
  priceRequiredScheme,
  uuidOptionSchema,
} from '~/shared/lib/schemas'
import { Car } from './car'
import { ApiModel, ToOneRelation } from './core'

const relationsSchema = z.object({
  counterpartyId: uuidOptionSchema,
  carLicenseeOption: uuidOptionSchema,
})

const attributeSchema = z.object({
  number: z.string().trim().min(1, 'Обязательное поле'),
  issuedDate: dateSchema,
  expirationDate: dateSchema,
  premium: priceRequiredScheme,
  files: z
    .object({
      id: z.string(),
      name: z.string(),
      filepath: z.string().url(),
      preview: z.string().url(),
    })
    .array()
    .optional(),
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type OsgopDocumentAttributes = z.infer<typeof schema>

export class OsgopDocument extends ApiModel<typeof schema> {
  static jsonApiType = 'osgop-documents'

  static schema = schema

  setCar(id: UniqueId) {
    this.setRelation('car', new Car({}, id))
  }

  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }

  getFiles(): StsDocumentAttributes['files'] {
    return this.getAttribute('files')
  }

  counterparty(): ToOneRelation<Counterparty, this> {
    return this.hasOne(Counterparty)
  }
  getCounterparty(): Counterparty {
    return this.getRelation('counterparty')
  }
  setCounterparty(id: UniqueId) {
    const region = new Counterparty()
    region.setApiId(id)
    this.setRelation('counterparty', region)
  }

  carLicensee(): ToOneRelation<CarLicensee, this> {
    return this.hasOne(CarLicensee)
  }
  getCarLicensee(): CarLicensee {
    return this.getRelation('carLicensee')
  }
  setCarLicensee(id: UniqueId) {
    const carLicensee = new CarLicensee()
    carLicensee.setApiId(id)
    this.setRelation('carLicensee', carLicensee)
  }
}
