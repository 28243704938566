import { userOptionsStore } from '~/shared/api'

export const usePermissions = () => {
  const permissions = userOptionsStore.permissions || []

  const checkPermissions = (permission: string) =>
    permissions.includes(permission)

  const canCarsUpdate = checkPermissions('update cars') // Создавать/изменять автомобили
  const canCarsView = checkPermissions('view cars') // Просматривать автомобили
  const canCarStatusUpdate = checkPermissions('update car status') // Обновлять статус автомобиля
  const canExportCarDocumentsStatusReport = checkPermissions(
    'export car documents status report',
  ) // Выгружать отчет по документам автомобиля
  const canCarDocumentsView = checkPermissions('view car documents') // Просматривать документы автомобиля
  const canCarDocumentsUpdate = checkPermissions('update car documents') // Создавать/изменять документы автомобиля
  const canCarPropertyTypeUpdate = checkPermissions('update car property type') // Изменять тип собственности по автомобилю

  const canDriversUpdate = checkPermissions('update drivers') // Создавать/изменять водителей
  const canDriversView = checkPermissions('view drivers') // Просматривать водителей

  const canDriverStatusDecide = checkPermissions('decide status drivers') // Одобрять/отклонять водителей

  const canDriverSecurityCheck = checkPermissions('security check') // Изменять результаты проверки СБ
  const canDriverSendApproval = checkPermissions('send driver for approval') // Запрашивать согласование руководителя
  const canDriverChecksUpdate = checkPermissions('update driver checks') // Обновлять проверки водителей
  const canDriverChecksView = checkPermissions('view driver checks') // Просматривать проверки водителей

  const canDriverUpdateAggregator = checkPermissions(
    'update aggregator drivers',
  ) // Изменять условия в аккаунте агрегатора
  const canDriverSetMainAggregator = checkPermissions(
    'set main aggregator drivers',
  ) // Изменить основной аккаунт агрегатора водителя
  const canDriverBalanceTransferTasks = checkPermissions(
    'balance transfer tasks',
  ) // Перенести баланс водителя между аккаунтами агрегатора

  const canRentalContractsCreate = checkPermissions('create rental contracts') // Создавать/расторгать договоры аренды
  const canRentalContractsUpdate = checkPermissions('update rental contracts') // Изменять договоры аренды
  const canRentalContractsView = checkPermissions('view rental contracts') // Просматривать договоры аренды
  const canRentalContractsSuspend = checkPermissions('suspend rental contracts') // Приостановка списаний по договору

  const canTravelSheetsCreate = checkPermissions('create travel sheets') // Создавать путевые листы
  const canTravelSheetsView = checkPermissions('view travel sheets') // Просматривать путевые листы

  const canWorkRulesArchive = checkPermissions('archive work rules') // Отправлять в архив условия работы
  const canWorkRulesCreate = checkPermissions('create work rules') // Создавать условия работы
  const canWorkRulesView = checkPermissions('view work rules') // Просматривать условия работы

  const canTaxiparkUpdate = checkPermissions('update taxipark') // Обновлять информацию о таксопарке
  const canTaxiparkView = checkPermissions('view taxipark') // Просматривать информацию о таксопарке

  const canUsersUpdate = checkPermissions('update users') // Создавать/изменять пользователей
  const canUsersView = checkPermissions('view users') // Просматривать пользователей

  const canRegionsUpdate = checkPermissions('update regions') // Создавать/изменять регионы
  const canRegionsView = checkPermissions('view regions') // Просматривать регионы

  const canSubdivisionsUpdate = checkPermissions('update subdivisions') // Создавать/изменять подразделения
  const canSubdivisionsView = checkPermissions('view subdivisions') // Просматривать подразделения

  const canRolesUpdate = checkPermissions('update roles') // Создавать/изменять роли
  const canRolesView = checkPermissions('view roles') // Просматривать роли

  const canAggregatorAccountsView = checkPermissions('view aggregator accounts') // Просматривать аккаунты агрегаторов

  const canTaxiparkSectionView =
    canTaxiparkView ||
    canUsersView ||
    canRegionsView ||
    canSubdivisionsView ||
    canRolesView ||
    canAggregatorAccountsView

  const canCarOrganizationsUpdate = checkPermissions('update car organizations') // Создавать/изменять организации
  const canCarOrganizationsView = checkPermissions('view car organizations') // Просматривать организации

  const canBalancesExport = checkPermissions('export balances') // Выгружать список транзакций
  const canBalancesUpdate = checkPermissions('update balances') // Создавать транзакции
  const canBalancesView = checkPermissions('view balances') // Просматривать транзакции
  const canBalancesStorno = checkPermissions('storno balances') // Сторнировать транзакции
  const canMassImportTransactions = checkPermissions('mass import transactions') // Создавать массовые транзакции

  const canAppealsCreator = checkPermissions('creator appeals') // Быть Инициатором обращения
  const canAppealsResponsible = checkPermissions('responsible appeals') // Быть Ответственным по обращению
  const canAppealsReportExport = checkPermissions('export appeals report') // Выгружать отчет по обращениям
  const canAppealsView = canAppealsCreator || canAppealsResponsible

  const canBalanceSectionView = canAppealsView || canBalancesView

  const canAuditsView = checkPermissions('view audits') // Просмотр истории изменений

  return {
    canCarsUpdate,
    canCarsView,
    canCarStatusUpdate,
    canExportCarDocumentsStatusReport,
    canCarDocumentsView,
    canCarDocumentsUpdate,
    canCarPropertyTypeUpdate,
    canDriversUpdate,
    canDriversView,
    canDriverStatusDecide,
    canDriverSendApproval,
    canDriverSecurityCheck,
    canDriverUpdateAggregator,
    canDriverSetMainAggregator,
    canDriverBalanceTransferTasks,
    canDriverChecksUpdate,
    canDriverChecksView,
    canAggregatorAccountsView,
    canRentalContractsCreate,
    canRentalContractsUpdate,
    canRentalContractsView,
    canRentalContractsSuspend,
    canTravelSheetsCreate,
    canTravelSheetsView,
    canUsersUpdate,
    canUsersView,
    canWorkRulesArchive,
    canWorkRulesCreate,
    canWorkRulesView,
    canTaxiparkUpdate,
    canTaxiparkView,
    canRegionsUpdate,
    canRegionsView,
    canSubdivisionsUpdate,
    canSubdivisionsView,
    canRolesUpdate,
    canRolesView,
    canTaxiparkSectionView,
    canCarOrganizationsUpdate,
    canCarOrganizationsView,
    canBalancesExport,
    canBalancesUpdate,
    canBalancesView,
    canBalancesStorno,
    canAppealsCreator,
    canAppealsResponsible,
    canAppealsReportExport,
    canAppealsView,
    canBalanceSectionView,
    canAuditsView,
    canMassImportTransactions,
  }
}
