import { z } from 'zod'
import { AppealType } from '~/shared/api/appealType'
import { BalanceHistory } from '~/shared/api/balanceHistory'
import { Car } from '~/shared/api/car'
import { CarModel } from '~/shared/api/carModel'
import { Driver } from '~/shared/api/driver'
import { Employee } from '~/shared/api/employee'
import { LatestStatusTransition } from '~/shared/api/latestStatusTransition'
import { Subdivision } from '~/shared/api/subdivision'
import { appealsStatusEnumLabels } from '~/shared/config/constants'
import { AppealsStatusEnum } from '~/shared/config/enums'
import {
  dateSchema,
  priceRequiredWithZeroScheme,
  uuidOptionSchema,
} from '~/shared/lib/schemas'
import { ApiModel, ToManyRelation, ToOneRelation } from './core'

const attributeSchema = z.object({
  number: z.string(),
  amount: priceRequiredWithZeroScheme,
  status: z.nativeEnum(AppealsStatusEnum),
  creatorComment: z.string().min(1, 'Обязательное поле'),
  responsibleComment: z.string(),
  statusUpdatedAt: dateSchema.optional(),
  files: z
    .object({
      id: z.string(),
      name: z.string(),
      originalUrl: z.string().url(),
      previewUrl: z.string().url(),
    })
    .array()
    .optional(),
  daysInStatus: z.number().optional(),
})

const relationsSchema = z.object({
  appealTypeId: uuidOptionSchema,
  driverId: uuidOptionSchema,
  carId: uuidOptionSchema,
  creatorId: uuidOptionSchema,
  responsibleId: uuidOptionSchema,
  subdivisionId: uuidOptionSchema,
  regionId: uuidOptionSchema,
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type AppealAttributes = z.infer<typeof schema>

export type AppealUpdateByCreator = {
  driverId: UniqueId
  carId: UniqueId
  subdivisionId: UniqueId
  appealTypeId: UniqueId
  amount: number
  creatorComment: string
}

export class Appeal extends ApiModel<typeof schema> {
  static jsonApiType = 'appeals'
  static schema = schema

  getStatus(): AppealsStatusEnum {
    return this.getAttribute('status')
  }

  getStatusLabel(): string {
    return appealsStatusEnumLabels[this.getStatus()]
  }
  getStatusColor() {
    const status = this.getStatus()
    switch (status) {
      case AppealsStatusEnum.NEW:
        return 'yellow'
      case AppealsStatusEnum.PROCESSING:
        return 'brand'
      case AppealsStatusEnum.REJECTED:
        return 'red'
      case AppealsStatusEnum.SOLVED:
        return 'green'
      default:
        return 'gray'
    }
  }

  getFiles(): AppealAttributes['files'] {
    return this.getAttribute('files')
  }

  getResponsibleComment(): string {
    return this.getAttribute('responsibleComment')
  }

  getNumber(): string {
    return this.getAttribute('number')
  }

  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }

  getAmount(): number {
    return this.getAttribute('amount')
  }

  creator(): ToOneRelation<Employee, this> {
    return this.hasOne(Employee)
  }
  getCreator(): Employee {
    return this.getRelation('creator')
  }

  responsible(): ToOneRelation<Employee, this> {
    return this.hasOne(Employee)
  }
  getResponsible(): Employee {
    return this.getRelation('responsible')
  }

  balanceHistories(): ToManyRelation<BalanceHistory, this> {
    return this.hasMany(BalanceHistory)
  }
  getBalanceHistories(): BalanceHistory[] {
    return this.getRelation('balanceHistories') ?? []
  }

  latestStatusTransition(): ToOneRelation<LatestStatusTransition, this> {
    return this.hasOne(LatestStatusTransition)
  }
  getLatestStatusTransition(): LatestStatusTransition {
    return this.getRelation('latestStatusTransition')
  }

  appealType(): ToOneRelation<AppealType, this> {
    return this.hasOne(AppealType)
  }
  getAppealType(): AppealType {
    return this.getRelation('appealType')
  }
  setAppealType(id: UniqueId) {
    const appealType = new AppealType()
    appealType.setApiId(id)
    this.setRelation('appealType', appealType)
  }

  driver(): ToOneRelation<Driver, this> {
    return this.hasOne(Driver)
  }
  getDriver(): Driver {
    return this.getRelation('driver')
  }
  setDriver(id: UniqueId) {
    const driver = new Driver()
    driver.setApiId(id)
    this.setRelation('driver', driver)
  }

  car(): ToOneRelation<Car, this> {
    return this.hasOne(Car)
  }
  getCar(): Car {
    return this.getRelation('car')
  }
  setCar(id: UniqueId) {
    const car = new Car()
    car.setApiId(id)
    this.setRelation('car', car)
  }

  carModel(): ToOneRelation<CarModel, this> {
    return this.hasOne(CarModel)
  }
  getCarModel(): CarModel {
    return this.getRelation('carModel')
  }
  setCarModel(id: UniqueId) {
    const carModel = new CarModel()
    carModel.setApiId(id)
    this.setRelation('carModel', carModel)
  }

  subdivision(): ToOneRelation<Subdivision, this> {
    return this.hasOne(Subdivision)
  }
  getSubdivision(): Subdivision {
    return this.getRelation('subdivision')
  }
  setSubdivision(id: UniqueId) {
    const subdivision = new Subdivision()
    subdivision.setApiId(id)
    this.setRelation('subdivision', subdivision)
  }

  static async exportAppeal(filters: string): Promise<Blob> {
    const url = `${Appeal.getJsonApiUrl()}/actions/export?${filters}`
    const client = Appeal.httpClient.getImplementingClient()

    const response = await client.get(url, {
      responseType: 'blob',
    })

    return response?.data
  }

  static async saveFiles(file: File, appealId: UniqueId) {
    const url = `${Appeal.getJsonApiUrl()}/${appealId}/actions/save-file`

    const data = new FormData()
    data.append('file', file)
    const client = Appeal.httpClient.getImplementingClient()

    return await client.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  static async deleteFile(appealId: UniqueId, fileId: UniqueId) {
    const url = `${Appeal.getJsonApiUrl()}/${appealId}/actions/delete-file`
    const client = Car.httpClient.getImplementingClient()
    return await client.post(url, { fileId })
  }

  static async updateStatus(status: AppealsStatusEnum, appealId: UniqueId) {
    const url = `${Appeal.getJsonApiUrl()}/${appealId}/actions/update-status`
    const client = Car.httpClient.getImplementingClient()
    return await client.post(url, { status })
  }

  static async updateByResponsible(
    responsibleComment: string,
    appealId: UniqueId,
  ) {
    const url = `${Appeal.getJsonApiUrl()}/${appealId}/actions/update-by-responsible`
    const client = Appeal.httpClient.getImplementingClient()
    return await client.post(url, { responsibleComment })
  }

  static async updateByCreator(
    data: AppealUpdateByCreator,
    appealId: UniqueId,
  ) {
    const url = `${Appeal.getJsonApiUrl()}/${appealId}/actions/update-by-creator`
    const client = Appeal.httpClient.getImplementingClient()
    return await client.post(url, data)
  }
}
