import { Box, Typography } from '@mui/joy'
import { DataGrid } from '@mui/x-data-grid'
import { useMemo } from 'react'
import { EventAuditEnum } from '~/shared/config/enums'
import { GridCell } from '~/shared/ui/AsyncGrid'
import { balanceTransferColumns, defaultColumns } from './columns'
import { GridRow } from './types'

type HistoryChangesModalProps = {
  changeFields?: Omit<GridRow, 'id'>[]
  event?: EventAuditEnum
}

export function ChangedFieldsList({
  changeFields = [],
  event,
}: HistoryChangesModalProps) {
  const rows = useMemo(
    () =>
      changeFields?.map((changeField, index) => ({
        ...changeField,
        id: String(index),
      })),
    [changeFields],
  )

  const columns = useMemo(() => {
    if (event === EventAuditEnum.BALANCE_TRANSFER) {
      return balanceTransferColumns
    }
    return defaultColumns
  }, [event])

  return (
    <>
      <Typography sx={{ marginBottom: '12px', fontWeight: 600 }}>
        Изменения
      </Typography>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          density='compact'
          disableColumnMenu
          disableColumnSelector
          hideFooter
          autoHeight
          components={{
            Cell: GridCell,
          }}
        />
      </Box>
    </>
  )
}
