import { useStore } from 'effector-react'
import { useMemo } from 'react'
import { FormValues, Form } from 'src/entities/Driver'
import { formSubmitted, createFx } from './model'

type AddDriverProps = {
  onChange?: (formData: Partial<FormValues>) => void
}

export function AddDriver({ onChange }: AddDriverProps) {
  const isCreating = useStore(createFx.pending)

  const defaultValues = useMemo(() => {
    return {
      data: {
        passportExpirationDate: '',
        registrationDate: '',
        registrationExpirationDate: '',
        comment: null,
        callsign: null,
        lastJob: null,
        birthPlace: '',
      },
    }
  }, [])

  return (
    <Form
      onChange={onChange}
      isSubmitting={isCreating}
      onSuccess={formSubmitted}
      defaultValues={defaultValues}
      isCreating
    />
  )
}
