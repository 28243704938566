import { LinearProgress } from '@mui/joy'
import { useGate, useStore } from 'effector-react'
import { Container } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { $tokenById, Gate, requestTokenFx } from './model'

type IframeContentProps = {
  id: UniqueId
}

export function IframeContent({ id }: IframeContentProps) {
  useGate(Gate, { id })

  const token = useStore($tokenById)?.[id]
  const isTokenPending = useStore(requestTokenFx.pending)

  return (
    <Container sx={{ padding: 0, overflow: 'hidden' }}>
      {isTokenPending && <LinearProgress size='lg' sx={{ margin: '32px' }} />}

      {!isTokenPending && Boolean(token) && (
        <iframe
          key={token}
          src={`https://datalens.yandex.cloud/embeds/dash#dl_embed_token=${token}`}
          width='100%'
          height='800px'
          frameBorder='0'
        />
      )}

      {!isTokenPending && !token && <NotFound message='График не найден' />}
    </Container>
  )
}
