import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { ReactNode } from 'react'
import { useForm } from 'react-hook-form'
import { FlowStatement } from '~/shared/api'
import {
  transactionPaymentTypeEnumLabels,
  transactionPaymentTypeEnumOptions,
} from '~/shared/config/constants'
import { TransactionPaymentTypeEnum } from '~/shared/config/enums'

import {
  AsyncAutocompleteInput,
  AutocompleteInput,
  FormButtons,
  FormProvider,
} from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { FormValues, formSchema } from './model'

type FormProps = {
  onSuccess?: (formValues: FormValues) => void
  isSubmitting: boolean
  children: ReactNode
}

export function Form({ onSuccess, isSubmitting, children }: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      paymentType: {
        id: TransactionPaymentTypeEnum.NON_CASH,
        label:
          transactionPaymentTypeEnumLabels[TransactionPaymentTypeEnum.NON_CASH],
      },
    },
  })

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Container>
        <ContainerTitle>Общая информация</ContainerTitle>
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <AsyncAutocompleteInput
              label='Статья оборотов'
              placeholder='Выбрать'
              name='flowStatementId'
              fetchOptions={FlowStatement.fetchOptions}
              canAsyncSearch={false}
            />
          </Grid>
          <Grid xs={6}>
            <AutocompleteInput
              label='Способ оплаты'
              placeholder='Выбрать'
              name='paymentType'
              options={transactionPaymentTypeEnumOptions}
            />
          </Grid>
        </Grid>
      </Container>

      {children}

      <FormButtons loading={isSubmitting} isCreating />
    </FormProvider>
  )
}
